import config from '../config'
import isUndefined from 'lodash/isUndefined'
import isObject from 'lodash/isObject'

export default function (repository, resultKey, hasSearch = false, data = {}) {
  return {
    data () {
      return Object.assign(data, {
        items: null,
        query: { search: null },
        filter: {},
        pagination: { current: 1, total: null, limit: config.paginationLimit },
        loading: false
      })
    },
    created () {
      this.updateQuery()
      this.fetchData()
    },
    watch: {
      // call again the method if the route changes
      '$route': 'onUpdateRoute'
    },
    methods: {
      haveItems () {
        return this.items && this.items.length
      },
      updateQuery () {
        this.pagination.current = Math.max(this.$route.query.page || 1, 1)
        if (hasSearch) {
          this.query.search = this.$route.query.search || null
        }
      },
      onUpdateRoute () {
        if (this.loading) return

        this.updateQuery()
        this.fetchData()
      },
      changePage (pagination) {
        if (this.loading) return

        this.pagination.current = pagination.page
        this.fetchData()
      },
      search () {
        if (!hasSearch || this.loading) return

        this.pagination.current = 1
        this.fetchData()
      },
      fetchData () {
        if (this.loading) return

        this.loading = true

        let query = {
          page: this.pagination.current
        }

        if (hasSearch) {
          query.search = this.query.search
        }

        query.filter = this.filter

        let request = Object.assign(
          { limit: this.pagination.limit }, query
        )

        if (!isUndefined(this.requestParams) && isObject(this.requestParams)) {
          request = Object.assign(
            request, this.requestParams
          )
        }

        repository.getAll(request).then(
          response => {
            this.items = response[resultKey]
            this.pagination.total = response.pages

            this.$router.push({ query: query })

            setTimeout(() => { this.loading = false }, 0)
          },
          error => {
            if (error) {
              this.loading = false // TODO: show message
            }
          }
        )
      },
      removeConfirm (item, $index, confirmMessage) {
        if (item.removing) return
        item.removing = true

        if (!window.confirm(confirmMessage)) {
          item.removing = false
          return
        }

        repository.remove(item.uuid).then(
          response => {
            this.items.splice($index, 1)
          },
          error => {
            if (error) {
              item.removing = false // TODO: show message
            }
          }
        )
      }
    }
  }
}
