<template xmlns: xmlns:>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <h4 class="title">Пользователи</h4>

                    <nav class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <p class="control has-addons">
                                    <input v-model.trim="query.search" type="text" placeholder="Поиск"
                                           class="input" @keyup.enter="search">
                                    <a class="button is-info" :class="{ 'is-loading': loading }"
                                       @click.prevent="search">Найти</a>
                                </p>
                            </div>
                            <div class="level-item">
                                <p class="control has-addons">
                                   <select name="moderated" v-model="filter.moderated" v-on:change="search" class="select">
                                       <option v-bind:value="null">Все</option>
                                       <option v-bind:value="true">Активные</option>
                                       <option v-bind:value="false">Неактивные</option>
                                   </select>
                                </p>
                            </div>
                        </div>
                    </nav>

                    <div class="notification is-primary" v-if="query.search && !haveItems()">
                        Ничего не найдено
                    </div>

                    <spinner v-show="loading"></spinner>

                    <table class="table is-striped" v-if="haveItems() && !loading">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th @click="sortBy('full_name')">Ф.И.О. <span v-html="sortingSymbol('full_name')"></span></th>
                            <th>Дата рождения</th>
                            <th>Телефон</th>
                            <th @click="sortBy('organization')">Организация <span v-html="sortingSymbol('organization')"></span></th>
                            <th>Статус</th>
                            <th>Модерация</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(user, index) in sortedItems">
                            <td>{{ user.medialog_collab_id }}</td>
                            <td>{{ user.full_name }}</td>
                            <td>{{ user.birthday | date }}</td>
                            <td>{{ user.auth.username }}</td>
                            <td>{{ user.organization ? user.organization.title : '' }} <span v-if="user.organization">({{ (user.auth.roles.indexOf('ROLE_ADMIN_BUSINESS_COLLAB') !== -1) ? 'Администратор' : 'Сотрудник' }})</span></td>
                            <td>{{ user.moderated ? 'Активен' : 'Нет' }}</td>
                            <td><a @click.prevent="toggle(user)" href="#">{{ !user.moderated ?
                                'Активировать':'Деактивировать'}}</a></td>
                            <td class="is-icon">
                                <router-link :to="{ name: 'CollabDoctor', params: { id: user.uuid }}">
                                    <i class="fa fa-edit"></i>
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <pagination
                            @change="changePage"
                            :total-pages="pagination.total"
                            :limit="pagination.limit"
                            :page="pagination.current"
                    ></pagination>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import collabService from '../../services/collaborators'
import itemWithFilterListView from '../../mixins/itemWithFilterListView'
import itemListViewSorting from '../../mixins/itemListViewSorting'

export default {
  mixins: [
    itemWithFilterListView(collabService, 'users', true),
    itemListViewSorting
  ],
  methods: {
    toggle: function (user) {
      if (user.moderated) {
        user.moderated = false
      } else {
        user.moderated = true
      }
      collabService.update(user.uuid, user).then(() => {
      })
    }
  }
}
</script>
