import _ from 'lodash'

export default {
  data () {
    return {
      sorting: { field: null, order: null }
    }
  },
  computed: {
    sortedItems () {
      return _.orderBy(this.items, [this.sorting.field], [this.sorting.order])
    }
  },
  methods: {
    sortBy (field) {
      if (this.sorting.field !== field) {
        this.sorting = { field: field, order: 'asc' }
      } else if (this.sorting.order === 'asc') {
        this.sorting = { field: field, order: 'desc' }
      } else if (this.sorting.order === 'desc') {
        this.sorting = { field: null, order: null }
      }
    },
    sortingSymbol (field) {
      if (this.sorting.field !== field) return ''
      if (this.sorting.order === 'asc') return '↓'
      if (this.sorting.order === 'desc') return '↑'
    }
  }
}
